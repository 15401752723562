import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/app/src/components/billing/FreeTrial/FreeTrial.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/app/src/components/GlobalSearch/GlobalSearch.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/app/src/components/HierarchyNavigation/HierarchyNavigation.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/app/src/components/Navbar/MainMenu.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/app/src/components/Navbar/Navbar.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/app/src/components/Navbar/NavbarLogo.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/app/src/components/PermissionsProvider/PermissionsProviderClient.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchContextProvider"] */ "/vercel/path0/packages/app/src/lib/search/context.js");
;
import(/* webpackMode: "eager", webpackExports: ["Dropdown","DropdownTrigger","DropdownMenu","DropdownItem","Hyperlink"] */ "/vercel/path0/packages/components/src/index.js");
